select {
  height: calc(2.4em + 0.5rem + 2px);
  display: block;
  font: 400 1rem Open Sans;
  color: #666;
  border: 1px solid LightGray;
  text-align: center;
  padding: 0.75rem;
  margin: 0 1.5rem;
  flex: 0 0 auto;
}

select:hover {
  background: #f3f3f3;
}

select:focus {
  border: 1.5px solid #ed1968;
  outline: none;
}

textarea {
  display: block;
  border: 1px solid LightGray;
  font: 400 1.2rem Open Sans;
  width: 80%;
  text-align: justify;
  padding: 1rem 0.75rem 0;
  margin: 1rem 1.5rem;
  flex: 0 0 auto;
  outline: none;
}

textarea:focus {
  border: 2px solid #ed1968;
}

/* input:focus {
  border-color: #ed1964 !important;
  outline: none;
  border-style: solid;
  border-width: 2px;
} */

.react-datepicker__input-container input:focus {
  border-color: #ed1964 !important;
  outline: none;
  border-style: solid;
  border-width: 2px;
}

.PhoneInputInput {
  height: 2rem;
  width: 90%;
}

.PhoneInputInput:focus {
  border: 2px solid #ed1964;
  outline: none;
}

.PhoneInputCountry {
  margin-left: 1.5rem;
}
