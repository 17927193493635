@import url("https://fonts.googleapis.com/css?family=Baloo+2&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:ital,wght@0,400;0,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,300&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/3.1.0/css/font-awesome.min.css");

html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  position: relative;
  background-color: #f3f3f3;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
